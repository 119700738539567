body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(90deg, #234483 0%, #70ACD0 100%);
  overflow-x: hidden;
}

header {
  width: 100%;

}

html {
  scroll-behavior: smooth;
}
