.section3-section {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.contactbox1-div {
  display: grid;
  grid-template-columns: 1fr 1.5fr; /* Adjust the column width ratio */
  grid-template-areas:
    "contactinfo contactform"
    "map map"; /* Map spans full width at the bottom */
  gap: 20px;
  width: 90%;
}

.contactinfo-div,
.contactform-div {
  background-color: #fff;
  padding: 20px; /* Reduce padding */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-height: 400px; /* Set a shorter minimum height */
}

.contactinfo-div {
  grid-area: contactinfo;
}

.contactinfo-div > h2 {
  margin: 0;
}

.contactinfo-div > p {
  font-size: 1.1rem;
  line-height: 2;
}

.contactform-div {
  grid-area: contactform;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.contactform-div form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.contactform-div form input,
.contactform-div form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.contactform-div form textarea {
  resize: none;
  flex-grow: 1;
}

.contactform-div form button {
  padding: 10px;
  background-color: #0099ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.contactform-div form button:hover {
  background-color: #007acc;
}

.map-div {
  grid-area: map;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: auto;
  text-align: center;
  justify-self: stretch;
}

.map-div img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media only screen and (max-width: 1050px) {
  .contactbox1-div {
    grid-template-columns: 1fr; 
    grid-template-areas:
      "contactinfo"
      "contactform"
      "map"; 
  }

  .contactinfo-div,
  .contactform-div,
  .map-div {
    width: 100%; 
  }
}
@media only screen and (max-width: 800px) {
  .contactinfo-div > p,  {
    font-size: 0.9rem;
    
  }
  .contactform-div label {
    font-size: 0.9rem;
  }
  
}