.container-div {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}
.title-h1 {
  font-size: 5rem;
  font-weight: 400;
  color: white;
  margin: 0;
}
.text-p {
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  max-width: 90%;
  margin: 0 0 12px 0;
}

@media only screen and (max-width: 1200px) {
  .imagebox-div {
    display: none;
  }
}
@media only screen and (max-width: 650px) {
  .title-h1 {
    font-size: 2.8rem;
  }
}
