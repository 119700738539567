.footer-footer {
    display: flex;
    height: 7vh;
    background-color: white;
    color: grey;
    justify-content: center;
    align-items: center;
}

.lefttext-div {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.lefttext-div > a {
    color: grey;
}
.lefttext-div > p {
    margin: 0;
}

.footertext-div {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footertext-div > p {
    margin: 0;
}
@media only screen and (max-width: 450px) {
    .footertext-div > p, .lefttext-div > a, .lefttext-div > p {
        font-size: 0.8rem;
    }
  }
  