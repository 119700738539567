.header1-h1 {
  font-size: 3rem;
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.text1-p {
  font-size: 1.3rem;
  color: white;
  font-weight: 300;
  width: 80%;
}
.section2-section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100vh;
  flex-direction: column;
}
@media only screen and (max-width: 650px) {
  .text1-p {
    font-size: 1.1rem;
  }
  .header1-h1 {
    font-size: 2rem;
    
  }
}
