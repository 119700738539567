.section1-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 150px);
}

.button-button {
  font-size: 1.5rem;
  font-weight: 300;
  color: black;
  background-color: white;
  border: none;
  padding: 10px 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 15px;
  color: #333333;
  transition: box-shadow 0.3s;
  margin-right: 15px;
}

.button-button:active {
  transition: box-shadow 0.3s;
}

.arrow1-img {
  margin-left: 30px;
  cursor: pointer;
}
.arrow2-img {
  cursor: pointer;
  margin-right: 30px;
}

.container-transition-enter {
  opacity: 0;
  transform: translateX(200px);
}

.container-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.container-transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.container-transition-exit-active {
  opacity: 0;
  transform: translateX(-200px);
  transition: opacity 500ms, transform 500ms;
}
.container-transition2-enter {
  opacity: 0;
  transform: translateX(-200px);
}

.container-transition2-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.container-transition2-exit {
  opacity: 1;
  transform: translateX(0);
}

.container-transition2-exit-active {
  opacity: 0;
  transform: translateX(200px);
  transition: opacity 500ms, transform 500ms;
}
.box-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomarrow-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-container {
  display: flex;
  justify-content: center;
}

.arrow3-img {
  position: relative; /* Use relative since it's now inside a container */
  animation: bounce 2s infinite;
  transition: opacity 0.5s ease-in-out;
  width: 100%; /* Ensures the arrow scales with the container */
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0); /* Start and end at the original position */
  }
  50% {
    transform: translateY(-10px); /* Move up by 10px */
  }
}

@media only screen and (max-width: 650px) {
  .arrow1-img {
    margin-left: 5px;
  }
  .arrow2-img {
    margin-right: 5px;
  }
  .button-button {
    font-size: 1.2rem;
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 450px) {
  .arrow-container {
    width: 300px;
  }
}
