.topbox-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 30px;
}

.contactbox-div {
  margin-left: 15px;
  font-size: 14px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.maillink-a {
  display: inline-block; /* Ensure it takes full space */
  color: #234483;
  text-decoration: none;
  margin: none;
  height: 20px;
}
.facebooklink-a {
  display: inline-block; /* Ensure it takes full space */
  color: #234483;
  text-decoration: none;
  margin: none;
}
.languagebox-div {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: 20px;
  height: 25px;

  gap: 15px;
}

.navbar-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  border-bottom: 1px solid #e9ecef;
}

.logobox-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  gap: 20px;
}

.logoname-p {
  font-size: 38px;
  color: white;
}

.logoimg-img {
  width: 80px;
  height: 80px;
}

.nav-a {
  font-size: clamp(22px, 3vw, 26px);
 
  color: white;
  text-decoration: none;
  transition: color, transform 0.3s, 0.3s;
}

.nav-a:hover {
  color: #47c3fc;
  transform: translateY(-2px);
}
.navbox-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  gap: clamp(20px, 2vw, 40px);
  
}


.active {
  border-radius: 5px;
  background-color: #e6f7ff;
  transition: background-color 0.3s ease;
}

.languagebox-div svg:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media only screen and (max-width: 780px) {
  .logoname-p {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .tnumber-p, .maillink-p {
    display: none;
  }
  .logobox-div {
    
    margin-left: 5px;
    
  }
  .navbox-div {
    
    margin-right: 5px;
   
    
  }
}